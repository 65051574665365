/* Core CSS required for Ionic components to work properly */
import '@ionic/core/css/core.css'
import '@ionic/core/css/ionic.bundle.css'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import React from 'react'
import { Route } from 'react-router'
import './App.scss'
import ContextsProvider from './components/ContextsProvider'
import { PROMO_DETAIL, PROMO_INTRO } from './navigation/Routes'
import BlackFriday2021 from './pages/blackFriday/2021'
import BlackFriday2022 from './pages/blackFriday/2022'
import CyberMonday2021 from './pages/cyberMonday/2021'
import Valentines2022 from './pages/valentines/2022'
import ValentinesQuickie2022 from './pages/valentinesQuickie/2022'
import CyberMonday2022 from './pages/cyberMonday/2022'
import Carnival2023 from './pages/carnival/2023'
import Valentines2023 from './pages/valentines/2023'
import Promo692023 from './pages/diaDoSexo/2023/2023'
import blackFriday2023 from './pages/blackFriday/2023/2023'
import CyberMonday2023 from './pages/cyberMonday/2023/2023'
import Carnival2024 from './pages/carnival/2024'
import Valentines2024 from './pages/valentines/2024'
import Sixtynine2024 from './pages/diaDoSexo/2024/2024'
import WelcomeOffer from './pages/welcome/WelcomeOffer'
import PromoName from './plainTypes/PromoName'
import './theme.css'
import { entriesOf } from './utils/object'


const PROMO_PAGES: {
  [key in PromoName]?: {
    intro?: React.FC
    details: React.FC
  }
} = {
  // [PromoName.BLACK_FRIDAY_2020]: {
  //   intro: BlackFriday2020.Intro,
  //   details: BlackFriday2020.Detail,
  // },
  // [PromoName.VALENTINES_2021]: {
  //   intro: Valentines2021.Intro,
  //   details: Valentines2021.Detail,
  // },
  [PromoName.WELCOME]: {
    intro: WelcomeOffer.Detail,
    details: WelcomeOffer.Detail,
  },
  [PromoName.BLACK_FRIDAY_2021]: {
    intro: BlackFriday2021.Intro,
    details: BlackFriday2021.Detail,
  },
  [PromoName.CYBER_MONDAY_2021]: {
    intro: CyberMonday2021.Intro,
    details: CyberMonday2021.Detail,
  },
  [PromoName.VALENTINES_2022]: {
    intro: Valentines2022.Intro,
    details: Valentines2022.Detail,
  },
  [PromoName.VALENTINES_QUICKIE_2022]: {
    intro: ValentinesQuickie2022.Intro,
    details: ValentinesQuickie2022.Detail,
  },
  [PromoName.BLACK_FRIDAY_2022]: {
    intro: BlackFriday2022.Intro,
    details: BlackFriday2022.Detail,
  },
  [PromoName.CYBER_MONDAY_2022]: {
    intro: CyberMonday2022.Intro,
    details: CyberMonday2022.Detail,
  },
  [PromoName.CARNIVAL_2023]: {
    intro: Carnival2023.Intro,
    details: Carnival2023.Detail,
  },
  [PromoName.VALENTINES_2023]: {
    intro: Valentines2023.Intro,
    details: Valentines2023.Detail,
  },
  [PromoName.PROMO69_2023]: {
    intro: Promo692023.Intro,
    details: Promo692023.Detail,
  },
  [PromoName.BLACK_FRIDAY_2023]: {
    intro: blackFriday2023.Intro,
    details: blackFriday2023.Detail,
  },
  [PromoName.CYBER_MONDAY_2023]: {
    intro: CyberMonday2023.Intro,
    details: CyberMonday2023.Detail,
  },
  [PromoName.CARNIVAL_2024]: {
    intro: Carnival2024.Intro,
    details: Carnival2024.Detail,
  },
  [PromoName.VALENTINES_2024]: {
    intro: Valentines2024.Intro,
    details: Valentines2024.Detail,
  },
  [PromoName.SIXTYNINE_2024]: {
    intro: Sixtynine2024.Intro,
    details: Sixtynine2024.Detail,
  }
}

const App: React.FC = () => (
  <IonApp>
    <ContextsProvider>
      <IonRouterOutlet id="main">
        {/* TODO uncomment, when transfering purchasing from the app to here */}
        {/* <Route path={ROOT} component={GoldAdvantagesPage} /> */}
        {entriesOf(PROMO_PAGES).map(([name, pages]) => (pages?.intro
          ? <Route key={`intro_${name}`} exact path={PROMO_INTRO(name)} component={pages?.intro} />
          : <span key={`intro_${name}`} /> // lbaglie: cannot be null
        ))}
        {entriesOf(PROMO_PAGES).map(([name, pages]) => (
          <Route key={`detail_${name}`} exact path={PROMO_DETAIL(name)} component={pages?.details} />
        ))}
        <Route>
          Not found
        </Route>
      </IonRouterOutlet>
    </ContextsProvider>
  </IonApp>
)
export default App
