import { Container } from 'inversify'
import PurchaseController from '../controllers/billing/PurchaseController'
import PurchaseControllerImpl from '../controllers/billing/PurchaseControllerImpl'
import RestoreController from '../controllers/billing/RestoreController'
import RestoreControllerImpl from '../controllers/billing/RestoreControllerImpl'
import NotificationCenter from '../controllers/notificationCenter/NotificationCenter'
import NotificationCenterImpl from '../controllers/notificationCenter/NotificationCenterImpl'
import ErrorParser from '../errors/ErrorParser'
import ErrorParserImpl from '../errors/ErrorParserImpl'
import Navigator from '../navigation/Navigator'
import SimpleBrowserPlugin from '../plugins/browser/SimpleBrowserPlugin'
import WebViewParentSimpleBrowserPlugin from '../plugins/browser/WebViewParentSimpleBrowserPlugin'
import DIPlugin from '../plugins/dependencyInjection/DIPlugin'
import DIPluginImpl from '../plugins/dependencyInjection/DIPluginImpl'
import Localization from '../plugins/localization/Localization'
import ReactLocalization from '../plugins/localization/ReactLocalization'
import RuntimePlugin from '../plugins/runtime/RuntimePlugin'
import WebViewParentRuntimePlugin from '../plugins/runtime/WebViewParentRuntimePlugin'
import SessionPlugin from '../plugins/session/SessionPlugin'
import WebViewParentSessionPlugin from '../plugins/session/WebViewParentSessionPlugin'
import StorePlugin from '../plugins/store/StorePlugin'
import WebViewParentStorePlugin from '../plugins/store/WebViewParentStorePlugin'
import LocationWebViewParentPlugin from '../plugins/webViewParent/LocationWebViewParentPlugin'
import WebViewParentPlugin from '../plugins/webViewParent/WebViewParentPlugin'
import BillingRepository from '../repositories/billing/BillingRepository'
import BillingRepositoryImpl from '../repositories/billing/BillingRepositoryImpl'
import LogRepository from '../repositories/log/LogRepository'
import LogRepositoryImpl from '../repositories/log/LogRepositoryImpl'
import PromoRepository from '../repositories/promo/PromoRepository'
import PromoRepositoryImpl from '../repositories/promo/PromoRepositoryImpl'
import BillingParseService from '../services/billing/BillingParseService'
import BillingService from '../services/billing/BillingService'
import LogParseService from '../services/log/LogParseService'
import LogService from '../services/log/LogService'
import PromoParseService from '../services/promo/PromoParseService'
import PromoService from '../services/promo/PromoService'
import BlackFriday2021ViewModel from '../viewModels/blackfriday/2021'
import CyberMonday2021ViewModel from '../viewModels/cybermonday/2021'
import Valentines2022ViewModel from '../viewModels/valentines/2022'
import ValentinesQuickie2022ViewModel from '../viewModels/valentinesQuickie/2022'
import DefaultPromoDetailsViewModelImpl from '../viewModels/promo/DefaultPromoDetailsViewModelmpl'
import DefaultPromoIntroViewModelImpl from '../viewModels/promo/DefaultPromoIntroViewModelImpl'
import WelcomeOfferViewModel from '../viewModels/welcome/WelcomeOfferViewModel'
import BlackFriday2022ViewModel from '../viewModels/blackfriday/2022'
import CyberMonday2022ViewModel from '../viewModels/cybermonday/2022'
import Carnival2023ViewModel from '../viewModels/carnival/2023'
import Valentines2023ViewModel from '../viewModels/valentines/2023'
import Promo692023ViewModel from '../viewModels/diaDoSexo/2023'
import blackFriday2023ViewModel from '../viewModels/blackfriday/2023'
import CyberMonday2023ViewModel from '../viewModels/cybermonday/2023'
import Carnival2024ViewModel from '../viewModels/carnival/2024'
import Valentines2024ViewModel from '../viewModels/valentines/2024'
import Sixtynine2024ViewModel from '../viewModels/diaDoSexo/2024'

import { DIMappingEx } from './DIMapping'
import inject from './inject'

const promoViewModelMappings = [
  // BlackFriday2020ViewModel,
  // Valentines2021ViewModel,
  WelcomeOfferViewModel,
  BlackFriday2021ViewModel,
  CyberMonday2021ViewModel,
  Valentines2022ViewModel,
  ValentinesQuickie2022ViewModel,
  BlackFriday2022ViewModel,
  CyberMonday2022ViewModel,
  Carnival2023ViewModel,
  Valentines2023ViewModel,
  Promo692023ViewModel,
  blackFriday2023ViewModel,
  CyberMonday2023ViewModel,
  Carnival2024ViewModel,
  Valentines2024ViewModel,
  Sixtynine2024ViewModel
]

const singletonMappings = DIMappingEx.new([
  // Repositories
  [BillingRepository.SYMBOL, BillingRepositoryImpl],
  [LogRepository.SYMBOL, LogRepositoryImpl],
  [PromoRepository.SYMBOL, PromoRepositoryImpl],

  // Services
  [BillingService.SYMBOL, BillingParseService],
  [LogService.SYMBOL, LogParseService],
  [PromoService.SYMBOL, PromoParseService],

  // MISC
  [ErrorParser.SYMBOL, ErrorParserImpl],
  [NotificationCenter.SYMBOL, NotificationCenterImpl],

  // Plugins
  [Localization.SYMBOL, ReactLocalization],
  [RuntimePlugin.SYMBOL, WebViewParentRuntimePlugin],
  [StorePlugin.SYMBOL, WebViewParentStorePlugin],
  [SimpleBrowserPlugin.SYMBOL, WebViewParentSimpleBrowserPlugin],
  [WebViewParentPlugin.SYMBOL, LocationWebViewParentPlugin],
  [SessionPlugin.SYMBOL, WebViewParentSessionPlugin],
])

const transientMappings = DIMappingEx.new([
  [PurchaseController.SYMBOL, PurchaseControllerImpl],
  [RestoreController.SYMBOL, RestoreControllerImpl],
])

const ReactIoCContainer = new Container()

promoViewModelMappings.forEach((vm) => {
  if (vm.SYMBOL_INTRO) {
    ReactIoCContainer.bind(vm.SYMBOL_INTRO)
      .toDynamicValue(({ container }) => inject(
        container.resolve(DefaultPromoIntroViewModelImpl),
        'promoName',
        vm.PROMO_NAME,
      ))
      .inSingletonScope()
  }

  ReactIoCContainer.bind(vm.SYMBOL_DETAIL)
    .toDynamicValue(({ container }) => inject(
      container.resolve(DefaultPromoDetailsViewModelImpl),
      'promoName',
      vm.PROMO_NAME,
    ))
    .inSingletonScope()
})

singletonMappings.forEach((clazz, dependency) => {
  ReactIoCContainer.bind(dependency).to(clazz).inSingletonScope()
})

transientMappings.forEach((clazz, dependency) => {
  ReactIoCContainer.bind(dependency).to(clazz).inTransientScope()
})

ReactIoCContainer
  .bind(DIPlugin.SYMBOL)
  .toConstantValue(new DIPluginImpl(ReactIoCContainer, singletonMappings))

// HACK lbaglie: see useViewModel(ViewModelType)
ReactIoCContainer.bind(Navigator.SYMBOL).toDynamicValue(() => undefined)

export default ReactIoCContainer
